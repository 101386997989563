import { Link } from "react-router-dom";
import "./Header.css";
import { ROUTES } from "../../constants/routes";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Container } from "../Container";

export const Header = () => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [isDropDownListVisible, setDropdownListVisibility] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 700) {
        setMenuVisibility(false);
      }
    });
  }, []);

  const handleLinkClick = () => {
    setMenuVisibility(false);
  };

  return (
    <header className="header">
      <Container>
        <div className="header__inner">
          <nav className="header__nav">
            <Link to={ROUTES.home} className="header__logo">
              <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.7124 37.7449H41.8279V33.9888H34.4543V31.7929H41.8279V22.0823H28.5248V19.022H24.7828V22.0847H15.1377V25.8408H15.1641L24.7204 37.7437L24.778 37.6859V37.7449H28.5152V25.8408H38.0763V28.0367H30.7124V37.7449ZM24.778 31.9012L19.8607 25.8408H24.778V31.9012Z"
                  fill="#575757"
                />
                <path
                  d="M51.5396 37.7291L52.3917 38.0052L53.0895 35.8348L55.7142 36.678L56.023 35.719L52.5461 34.6025L51.5396 37.7291Z"
                  fill="#575757"
                />
                <path
                  d="M51.4566 37.9199L49.9482 40.9604L50.6816 41.3226L51.709 39.2531L52.2375 39.6302L51.3289 41.4651L51.9851 41.7888L52.8937 39.9568L53.5142 40.1468L52.4988 42.1985L53.2322 42.5608L54.7286 39.5411L52.9709 38.9799L51.4566 37.9199Z"
                  fill="#575757"
                />
                <path
                  d="M51.9018 42.8602C51.7712 42.6346 51.5841 42.4327 51.3377 42.2545C51.0912 42.0764 50.8389 41.9606 50.5865 41.9071C50.3341 41.8537 50.0847 41.8566 49.8412 41.916C49.5977 41.9754 49.3691 42.0823 49.1553 42.2367C48.9416 42.3911 48.7515 42.5841 48.5853 42.8157C48.4101 43.0592 48.2913 43.2967 48.2319 43.5313C48.1696 43.7658 48.1607 43.9885 48.1963 44.2023C48.2022 44.2379 48.2171 44.2706 48.223 44.3033L47.7895 44.0509L47.3708 44.6299L48.903 45.7374L50.0372 44.1667L49.4612 43.751L48.8762 44.5586C48.8703 44.5408 48.8644 44.5259 48.8584 44.5081C48.8168 44.3448 48.8228 44.1696 48.8762 43.9826C48.9297 43.7955 49.0247 43.6085 49.1642 43.4155C49.3068 43.2165 49.4641 43.071 49.6363 42.9701C49.8086 42.8721 49.9867 42.8305 50.1767 42.8454C50.3638 42.8602 50.5568 42.9404 50.7468 43.077C50.9369 43.2136 51.0675 43.365 51.1417 43.5313C51.216 43.6975 51.2367 43.8698 51.2041 44.0509C51.1714 44.232 51.0883 44.4131 50.9547 44.5972C50.8448 44.7486 50.726 44.8733 50.6013 44.9654C50.4766 45.0574 50.349 45.1168 50.2183 45.1465C50.0877 45.1732 49.963 45.1643 49.8442 45.1198L49.2474 45.9452C49.5057 46.0699 49.7759 46.1204 50.055 46.1026C50.3341 46.0848 50.6073 45.9927 50.8804 45.8264C51.1506 45.6631 51.406 45.4167 51.6406 45.0901C51.8187 44.8466 51.9464 44.5942 52.0295 44.3389C52.1127 44.0835 52.1453 43.8312 52.1245 43.5788C52.1067 43.3264 52.0325 43.0889 51.9018 42.8632V42.8602Z"
                  fill="#575757"
                />
                <path
                  d="M46.5721 45.4284L46.8393 46.1439L45.4765 47.4534L44.7728 47.1594L43.9978 47.9047L47.7271 49.3863L48.6862 48.4659L47.35 44.6831L46.5751 45.4284H46.5721ZM47.1481 46.9753L47.6707 48.3768L46.293 47.7978L47.1481 46.9753Z"
                  fill="#575757"
                />
                <path
                  d="M41.085 49.8554L41.5927 50.5947L43.4692 49.3001L45.031 51.5716L45.8624 50.9985L43.7899 47.9907L41.085 49.8554Z"
                  fill="#575757"
                />
                <path
                  d="M38.983 50.9038L39.4373 51.943L38.4812 52.3616C38.2021 52.4834 37.9824 52.6378 37.8191 52.8219C37.6558 53.006 37.5608 53.2079 37.5281 53.4306C37.4955 53.6503 37.5311 53.8759 37.632 54.1046C37.733 54.3362 37.8755 54.5173 38.0596 54.645C38.2437 54.7726 38.4575 54.838 38.7009 54.8439C38.9474 54.8498 39.2087 54.7934 39.4878 54.6687L41.3673 53.8492L39.9064 50.5029L38.983 50.9067V50.9038ZM39.2235 53.9027C39.0751 53.968 38.9415 53.9828 38.8286 53.9442C38.7128 53.9086 38.6267 53.8225 38.5703 53.6918C38.5139 53.5642 38.5109 53.4454 38.5644 53.3355C38.6148 53.2257 38.7158 53.1396 38.8643 53.0743L39.7609 52.6823L40.1202 53.5078L39.2235 53.8997V53.9027Z"
                  fill="#575757"
                />
                <path
                  d="M35.3961 52.2544L35.7256 53.5282L35.2149 53.6588L33.9352 52.6315L32.804 52.9224L34.1638 53.9884C34.0807 54.0329 34.0005 54.0834 33.9322 54.1398C33.7541 54.2853 33.6324 54.4575 33.5641 54.6564C33.4958 54.8554 33.4898 55.0662 33.5463 55.2889C33.6056 55.5175 33.7125 55.7016 33.8699 55.8412C34.0273 55.9807 34.2173 56.0728 34.4429 56.1143C34.6686 56.1589 34.918 56.144 35.1882 56.0757L37.2874 55.5353L36.3729 51.999L35.3961 52.2514V52.2544ZM35.1348 55.3186C34.9774 55.3601 34.8468 55.3542 34.7399 55.3067C34.633 55.2592 34.5647 55.1701 34.5291 55.0365C34.4964 54.9088 34.5142 54.796 34.5825 54.701C34.6508 54.606 34.7636 54.5377 34.921 54.4991L35.9068 54.2437L36.1176 55.0632L35.1318 55.3186H35.1348Z"
                  fill="#575757"
                />
                <path
                  d="M26.8628 53.2224L26.8687 54.1191L29.1461 54.1102L29.158 56.8656L30.1675 56.8596L30.1497 53.2075L26.8628 53.2224Z"
                  fill="#575757"
                />
                <path
                  d="M23.2936 52.7236L23.1778 53.5342L25.4671 53.8608L25.2741 54.4844L23.2461 54.1934L23.1452 54.9179L25.1702 55.2059L25.182 55.8561L22.9195 55.5325L22.8037 56.3431L26.1381 56.8211L26.1233 54.9743L26.6548 53.2046L23.2936 52.7236Z"
                  fill="#575757"
                />
                <path
                  d="M22.3585 52.9257C22.2249 52.7149 22.0527 52.5308 21.8419 52.3764C21.6281 52.222 21.3846 52.1002 21.1144 52.0141C20.8294 51.9251 20.5651 51.8865 20.3246 51.9013C20.0841 51.9161 19.8674 51.9755 19.6773 52.0794C19.6447 52.0973 19.6209 52.1181 19.5912 52.1388L19.6952 51.6459L19.0122 51.4292L18.4422 53.2285L20.289 53.8135L20.5028 53.1365L19.5556 52.8366C19.5556 52.8366 19.5794 52.8128 19.5942 52.8039C19.7367 52.7119 19.906 52.6644 20.099 52.6555C20.292 52.6466 20.5028 52.6792 20.7284 52.7505C20.96 52.8247 21.1501 52.9257 21.2985 53.0593C21.447 53.1899 21.542 53.3503 21.5836 53.5344C21.6281 53.7184 21.6133 53.9233 21.542 54.149C21.4707 54.3717 21.3698 54.5439 21.2332 54.6656C21.0996 54.7874 20.9422 54.8616 20.7581 54.8883C20.574 54.915 20.3781 54.8913 20.1613 54.823C19.9832 54.7666 19.8288 54.6923 19.7011 54.6033C19.5734 54.5142 19.4784 54.4103 19.4101 54.2974C19.3418 54.1816 19.3121 54.0599 19.3181 53.9352L18.3471 53.6264C18.3115 53.9114 18.3471 54.1816 18.4511 54.44C18.558 54.6983 18.7302 54.9299 18.9707 55.1348C19.2112 55.3396 19.5259 55.5059 19.9089 55.6247C20.197 55.7167 20.4761 55.7612 20.7433 55.7583C21.0105 55.7583 21.2629 55.7078 21.4945 55.6098C21.7291 55.5118 21.931 55.3693 22.1061 55.1763C22.2784 54.9833 22.412 54.7398 22.504 54.4489C22.5961 54.1579 22.6287 53.8847 22.599 53.6264C22.5693 53.3681 22.4892 53.1335 22.3585 52.9227V52.9257Z"
                  fill="#575757"
                />
                <path
                  d="M17.9908 50.9188L17.394 51.3968L15.7253 50.509L15.7876 49.7489L14.8375 49.2441L14.5911 53.2496L15.7639 53.8731L18.9439 51.4265L17.9938 50.9217L17.9908 50.9188ZM15.5323 52.8814L15.654 51.3938L16.7022 51.952L15.5323 52.8844V52.8814Z"
                  fill="#575757"
                />
                <path
                  d="M12.0793 47.0767L11.5359 47.7893L13.3471 49.1699L11.6754 51.3612L12.4742 51.9729L14.6921 49.069L12.0793 47.0767Z"
                  fill="#575757"
                />
                <path
                  d="M9.58807 46.159L8.89328 45.381C8.69137 45.1524 8.47759 44.9921 8.25193 44.8941C8.02627 44.7961 7.80358 44.7694 7.58386 44.808C7.36414 44.8466 7.16224 44.9505 6.97518 45.1168C6.78515 45.286 6.66044 45.476 6.59512 45.6898C6.5298 45.9036 6.53574 46.1263 6.60403 46.3638C6.67529 46.5984 6.81187 46.83 7.01378 47.0586L8.37961 48.5878L11.1053 46.156L10.4343 45.4048L9.58807 46.159ZM7.49776 46.2035C7.49776 46.0818 7.54823 45.9749 7.65512 45.8799C7.75905 45.7878 7.87188 45.7492 7.99064 45.7641C8.10941 45.7789 8.22521 45.8472 8.3321 45.9689L8.98235 46.6993L8.31132 47.2991L7.66106 46.5687C7.55417 46.447 7.49776 46.3252 7.49776 46.2065V46.2035Z"
                  fill="#575757"
                />
                <path
                  d="M8.03207 42.4177L6.92456 43.1273L6.63952 42.6819L7.21554 41.1469L6.5831 40.1641L5.99817 41.7882C5.92988 41.7229 5.85862 41.6635 5.78142 41.616C5.58545 41.4943 5.38355 41.4319 5.17274 41.426C4.96192 41.423 4.76002 41.4824 4.56702 41.6071C4.36808 41.7348 4.22556 41.8951 4.14243 42.0881C4.05929 42.2811 4.03257 42.4919 4.06226 42.7176C4.09195 42.9462 4.18103 43.1778 4.33245 43.4124L5.50232 45.2354L8.57543 43.2609L8.03207 42.4117V42.4177ZM4.92629 42.7502C4.93817 42.6344 5.00349 42.5394 5.11632 42.4652C5.22915 42.3939 5.33901 42.3761 5.45184 42.4117C5.56467 42.4474 5.66265 42.5335 5.75173 42.6701L6.30103 43.5252L5.58842 43.9824L5.03912 43.1273C4.95005 42.9907 4.91442 42.8631 4.92629 42.7473V42.7502Z"
                  fill="#575757"
                />
                <path
                  d="M4.43637 34.6143L3.58718 34.8963L4.30869 37.0579L1.6958 37.9308L2.0135 38.8869L5.47855 37.7319L4.43637 34.6143Z"
                  fill="#575757"
                />
                <path
                  d="M4.39201 34.4151L3.80708 31.0718L3.00243 31.2143L3.4003 33.4917L2.74708 33.4976L2.39671 31.4815L1.67817 31.6092L2.02853 33.6253L1.41391 33.8391L1.01901 31.5854L0.214355 31.725L0.796317 35.0446L2.54517 34.4567L4.39201 34.4151Z"
                  fill="#575757"
                />
                <path
                  d="M1.10791 30.6986C1.34545 30.8026 1.61862 30.856 1.92147 30.853C2.2273 30.853 2.4975 30.7966 2.73503 30.6897C2.9696 30.5828 3.16853 30.4314 3.32887 30.2414C3.48921 30.0514 3.61094 29.8287 3.69111 29.5793C3.77128 29.3298 3.80988 29.0596 3.80988 28.7746C3.80988 28.4747 3.76237 28.2134 3.6733 27.9907C3.58422 27.7651 3.45951 27.578 3.30215 27.4296C3.27542 27.4058 3.24573 27.388 3.21901 27.3642L3.7208 27.3108L3.71487 26.5952L1.82646 26.6101L1.8413 28.5489L2.55094 28.543L2.54203 27.5454C2.54203 27.5454 2.57173 27.5661 2.58657 27.575C2.71722 27.6819 2.81817 27.8274 2.88349 28.0086C2.95178 28.1897 2.98741 28.3975 2.99038 28.6351C2.99038 28.8785 2.95475 29.0893 2.87458 29.2705C2.79442 29.4516 2.67565 29.5911 2.51234 29.6891C2.35201 29.7871 2.1501 29.8376 1.91553 29.8405C1.68097 29.8405 1.485 29.799 1.32763 29.7099C1.17027 29.6208 1.0515 29.4931 0.968362 29.3269C0.888194 29.1606 0.846625 28.9676 0.843656 28.739C0.843656 28.5519 0.86444 28.3827 0.908978 28.2342C0.956485 28.0857 1.02181 27.961 1.11088 27.8631C1.19996 27.7651 1.30388 27.6968 1.42859 27.6641L1.41968 26.6457C1.13761 26.6991 0.891163 26.8179 0.677381 26.999C0.463599 27.1801 0.297324 27.4177 0.175588 27.7087C0.0538506 28.0026 -0.00553322 28.35 0.000405169 28.7508C0.000405169 29.0537 0.0479123 29.3298 0.13105 29.5852C0.217156 29.8405 0.341862 30.0632 0.505168 30.2533C0.668474 30.4463 0.870379 30.5947 1.10791 30.6986Z"
                  fill="#575757"
                />
                <path
                  d="M3.87519 25.4663L3.23681 25.0477L3.55452 23.183L4.29682 23.0019L4.47794 21.9419L0.597201 22.9633L0.374512 24.2727L3.69407 26.5263L3.87519 25.4663ZM2.49452 24.5637L1.24152 23.7472L2.69345 23.3938L2.49452 24.5637Z"
                  fill="#575757"
                />
                <path
                  d="M5.6745 18.646L4.82828 18.3521L4.08005 20.5047L1.47606 19.6021L1.14648 20.5552L4.59669 21.7518L5.6745 18.646Z"
                  fill="#575757"
                />
                <path
                  d="M6.74939 16.5626L5.76956 15.9926L6.29214 15.0929C6.44654 14.8286 6.53264 14.5763 6.55343 14.3298C6.57421 14.0863 6.53264 13.8637 6.42872 13.6677C6.3248 13.4717 6.16149 13.3114 5.94771 13.1837C5.72799 13.056 5.50827 12.9966 5.28558 12.9996C5.06289 13.0056 4.85208 13.0798 4.65017 13.2193C4.44827 13.3589 4.27012 13.5608 4.11869 13.8251L3.08838 15.5977L6.24463 17.4326L6.75236 16.5597L6.74939 16.5626ZM4.78379 14.2882C4.86396 14.1487 4.96194 14.0596 5.07774 14.021C5.19354 13.9824 5.3123 14.0002 5.43404 14.0715C5.55578 14.1428 5.62704 14.2348 5.65079 14.3536C5.67455 14.4723 5.64485 14.6 5.56172 14.7425L5.06883 15.5888L4.2909 15.1374L4.78379 14.2912V14.2882Z"
                  fill="#575757"
                />
                <path
                  d="M8.84269 13.3525L7.82426 12.5212L8.15978 12.1114L9.79877 12.1827L10.5381 11.2771L8.813 11.2236C8.85457 11.1375 8.88723 11.0514 8.90802 10.9653C8.96443 10.7426 8.96146 10.5318 8.89911 10.3299C8.83676 10.128 8.71799 9.95579 8.53984 9.8103C8.35575 9.66184 8.15978 9.57573 7.95194 9.55792C7.74409 9.5401 7.53625 9.5787 7.32544 9.67669C7.11759 9.77467 6.9246 9.93204 6.74941 10.1488L5.38062 11.8264L8.21026 14.1364L8.84863 13.3555L8.84269 13.3525ZM7.23933 10.7278C7.34325 10.6031 7.45014 10.5259 7.56594 10.5021C7.67877 10.4784 7.78863 10.5081 7.89552 10.5942C7.99944 10.6773 8.04992 10.7783 8.04992 10.897C8.04992 11.0158 7.99944 11.1375 7.89552 11.2622L7.25121 12.052L6.59501 11.5176L7.23933 10.7278Z"
                  fill="#575757"
                />
                <path
                  d="M15.1432 7.50576L14.6087 6.78722L12.7827 8.14711L11.1377 5.93506L10.3271 6.5378L12.5065 9.4684L15.1432 7.50576Z"
                  fill="#575757"
                />
                <path
                  d="M15.3186 7.40193L18.3116 5.79856L17.9256 5.07705L15.8887 6.16971L15.6749 5.55212L17.4802 4.58713L17.1358 3.94282L15.3305 4.90781L14.9356 4.39117L16.9517 3.31038L16.5657 2.58887L13.5935 4.18035L14.701 5.65901L15.3186 7.40193Z"
                  fill="#575757"
                />
                <path
                  d="M18.3411 4.65519C18.5163 4.84522 18.7211 4.98774 18.9527 5.07979C19.1843 5.17183 19.4338 5.21934 19.695 5.21637C19.9563 5.2134 20.2265 5.16886 20.4967 5.07682C20.7788 4.9818 21.0134 4.85413 21.2004 4.69973C21.3845 4.54533 21.5241 4.37015 21.6191 4.17418C21.6339 4.14152 21.6428 4.10886 21.6547 4.0762L21.8626 4.53642L22.5395 4.3078L21.9338 2.51737L20.0989 3.13794L20.3275 3.81194L21.2717 3.49127C21.2657 3.50908 21.2628 3.5269 21.2568 3.54175C21.1945 3.69911 21.0876 3.83866 20.9391 3.9604C20.7877 4.08214 20.6006 4.18012 20.375 4.25732C20.1434 4.33452 19.9296 4.36421 19.7336 4.3464C19.5377 4.32858 19.3655 4.25732 19.2229 4.13261C19.0804 4.00791 18.9676 3.8357 18.8934 3.61301C18.8191 3.39032 18.7983 3.19138 18.834 3.01323C18.8696 2.83508 18.9527 2.68068 19.0834 2.553C19.214 2.42533 19.3892 2.32438 19.603 2.25015C19.7811 2.19076 19.9474 2.1581 20.1018 2.15513C20.2562 2.15513 20.3958 2.17889 20.5175 2.22936C20.6392 2.28281 20.7372 2.36298 20.8055 2.4669L21.7705 2.14029C21.631 1.89087 21.4409 1.69194 21.2034 1.54645C20.9659 1.40096 20.6897 1.31485 20.372 1.29407C20.0573 1.27031 19.7069 1.32376 19.3269 1.45143C19.0418 1.54942 18.7894 1.67709 18.5757 1.83743C18.3619 1.99777 18.1867 2.18482 18.056 2.40158C17.9254 2.61833 17.8482 2.85586 17.8215 3.11418C17.7948 3.3725 17.8334 3.64567 17.9313 3.93665C18.0293 4.22466 18.1659 4.46516 18.3411 4.65519Z"
                  fill="#575757"
                />
                <path
                  d="M23.8459 3.37936L25.7165 3.10619L26.1174 3.75644L27.1833 3.59908L25.0099 0.226074L23.6945 0.419072L22.5811 4.27308L23.647 4.11572L23.8459 3.37936ZM25.2504 2.35202L24.0746 2.52423L24.4635 1.0812L25.2474 2.35202H25.2504Z"
                  fill="#575757"
                />
                <path
                  d="M28.4781 0.0178152L27.4686 0L27.4062 3.65211L30.6902 3.70852L30.708 2.81479L28.4277 2.77323L28.4781 0.0178152Z"
                  fill="#575757"
                />
                <path
                  d="M33.2465 2.9696L34.2649 3.18338C34.5648 3.24573 34.832 3.2487 35.0725 3.19526C35.3101 3.13884 35.506 3.03195 35.6604 2.86864C35.8148 2.70534 35.9158 2.50343 35.9692 2.25996C36.0227 2.01352 36.0108 1.78489 35.9336 1.57408C35.8593 1.36326 35.7228 1.18511 35.5268 1.03665C35.3308 0.888193 35.0844 0.784271 34.7845 0.721918L32.7773 0.300293L32.0261 3.8752L33.0119 4.08304L33.2465 2.97257V2.9696ZM34.9033 1.69284C34.9745 1.78786 34.9983 1.90663 34.9686 2.04618C34.9389 2.18276 34.8736 2.28074 34.7667 2.34013C34.6598 2.39951 34.5292 2.41139 34.3718 2.37873L33.4127 2.17682L33.5968 1.29497L34.5559 1.49688C34.7162 1.52954 34.829 1.59486 34.9033 1.69284Z"
                  fill="#575757"
                />
                <path
                  d="M36.7145 5.05945L37.1866 3.83317L37.6795 4.0232L38.1248 5.60281L39.2145 6.02147L38.7246 4.36466C38.8196 4.37653 38.9117 4.38247 39.0007 4.37357C39.2294 4.35575 39.4283 4.28746 39.6005 4.16572C39.7727 4.04399 39.9004 3.87474 39.9835 3.66096C40.0697 3.44124 40.0875 3.22746 40.04 3.02258C39.9925 2.81771 39.8915 2.63362 39.7312 2.46734C39.5738 2.30107 39.363 2.16746 39.1017 2.0665L37.0797 1.28857L35.7703 4.69721L36.7115 5.05945H36.7145ZM39.0215 2.95429C39.0809 3.05524 39.0839 3.16807 39.0364 3.29575C38.9889 3.42045 38.9087 3.50062 38.7988 3.53625C38.686 3.57485 38.5554 3.56298 38.4039 3.50359L37.4538 3.13838L37.7567 2.34858L38.7068 2.71379C38.8582 2.77317 38.9651 2.85037 39.0215 2.95132V2.95429Z"
                  fill="#575757"
                />
                <path
                  d="M44.7462 8.49513L42.8875 7.17978L44.479 4.92913L43.6565 4.34717L41.5454 7.32824L44.2296 9.22852L44.7462 8.49513Z"
                  fill="#575757"
                />
                <path
                  d="M46.8337 11.704L47.4008 11.1132L45.7291 9.51573L46.2487 9.1238L47.7274 10.5371L48.2321 10.0086L46.7535 8.59528L47.1217 8.05786L48.7755 9.63747L49.3397 9.0466L46.9049 6.71875L45.8449 8.23007L44.3811 9.35836L46.8337 11.704Z"
                  fill="#575757"
                />
                <path
                  d="M48.4844 10.7664C48.2409 10.9505 48.0539 11.1553 47.9292 11.381C47.8015 11.6067 47.7302 11.8442 47.7154 12.0936C47.7005 12.343 47.7332 12.5924 47.8163 12.8418C47.8995 13.0912 48.0271 13.3318 48.1994 13.5604C48.3775 13.8009 48.5705 13.982 48.7754 14.1097C48.9803 14.2374 49.1911 14.3146 49.4049 14.3413C49.4405 14.3442 49.4761 14.3413 49.5088 14.3442L49.1376 14.6827L49.5682 15.2558L51.0795 14.1216L49.9156 12.5716L49.3455 12.9992L49.9452 13.7979C49.9274 13.7979 49.9096 13.7979 49.8918 13.7979C49.7226 13.789 49.5563 13.7326 49.3959 13.6257C49.2356 13.5188 49.0812 13.3733 48.9387 13.1833C48.7932 12.9873 48.6982 12.7943 48.6536 12.6013C48.6091 12.4083 48.624 12.2242 48.6952 12.0491C48.7665 11.8739 48.8971 11.7165 49.0872 11.574C49.2742 11.4344 49.4583 11.3513 49.6394 11.3305C49.8205 11.3097 49.9928 11.3394 50.1561 11.4255C50.3194 11.5116 50.4678 11.6423 50.6044 11.8264C50.7172 11.9778 50.8004 12.1263 50.8508 12.2717C50.9013 12.4172 50.9221 12.5568 50.9102 12.6904C50.8984 12.824 50.8538 12.9398 50.7737 13.0408L51.3853 13.8543C51.5813 13.6435 51.7089 13.403 51.7743 13.1298C51.8396 12.8596 51.8337 12.5687 51.7565 12.2599C51.6793 11.9511 51.5219 11.6363 51.2784 11.3157C51.0973 11.0752 50.8984 10.8762 50.6786 10.7218C50.4589 10.5674 50.2273 10.4605 49.9809 10.4041C49.7344 10.3477 49.485 10.3477 49.2326 10.4041C48.9803 10.4605 48.7308 10.5793 48.4844 10.7634V10.7664Z"
                  fill="#575757"
                />
                <path
                  d="M50.1026 16.2565L50.8657 16.2149L51.7119 17.9044L51.219 18.4893L51.7001 19.4514L54.2239 16.3307L53.6271 15.1431L49.6187 15.2945L50.0997 16.2565H50.1026ZM51.7505 16.1645L53.244 16.0813L52.282 17.2245L51.7505 16.1645Z"
                  fill="#575757"
                />
                <path
                  d="M52.6946 22.8178L53.5497 22.5536L52.8757 20.3771L55.5094 19.5636L55.2124 18.6016L51.7236 19.6794L52.6946 22.8178Z"
                  fill="#575757"
                />
                <path
                  d="M53.0627 25.1397L54.191 25.0179L54.3009 26.0542C54.3336 26.357 54.4137 26.6153 54.5414 26.8232C54.6691 27.034 54.8324 27.1854 55.0343 27.2804C55.2362 27.3755 55.4618 27.4111 55.7083 27.3844C55.9607 27.3576 56.1745 27.2745 56.3496 27.1379C56.5248 27.0013 56.6525 26.8173 56.7327 26.5857C56.8128 26.3541 56.8366 26.0868 56.8039 25.781L56.5842 23.7441L52.9529 24.1331L53.0598 25.1367L53.0627 25.1397ZM55.9161 26.1997C55.8478 26.2976 55.741 26.357 55.5984 26.3719C55.4589 26.3867 55.3461 26.3541 55.257 26.2709C55.1679 26.1878 55.1145 26.066 55.0966 25.9057L54.9927 24.9318L55.8894 24.8368L55.9933 25.8107C56.0112 25.971 55.9844 26.1017 55.9161 26.1997Z"
                  fill="#575757"
                />
                <path
                  d="M53.3508 27.9517L53.2855 28.9583L54.5978 29.0414L54.5652 29.5669L53.1904 30.4607L53.1162 31.6276L54.5533 30.6655C54.5711 30.7606 54.5919 30.8496 54.6246 30.9328C54.7077 31.1466 54.8324 31.3158 55.0017 31.4464C55.1679 31.5741 55.3669 31.6454 55.5985 31.6602C55.836 31.6751 56.0438 31.6305 56.225 31.5236C56.4061 31.4167 56.5516 31.2653 56.6644 31.0634C56.7772 30.8615 56.8425 30.624 56.8604 30.3449L56.9999 28.1833L53.3537 27.9487L53.3508 27.9517ZM55.9845 30.5319C55.9043 30.618 55.7974 30.6566 55.6608 30.6477C55.5272 30.6388 55.4262 30.5854 55.358 30.4904C55.2897 30.3953 55.26 30.2677 55.2718 30.1044L55.3372 29.0889L56.1834 29.1423L56.1181 30.1578C56.1062 30.3181 56.0646 30.4429 55.9845 30.529V30.5319Z"
                  fill="#575757"
                />
              </svg>
            </Link>

            <div className="header__menu__container">
              <div className="header__nav__link">Our services</div>
              <div className="header__menu">
                <Link to={ROUTES.publicRelations} className="header__menu__link">
                  Public relations
                </Link>
                <Link to={ROUTES.contentMarketing} className="header__menu__link">
                  Content marketing
                </Link>
                <Link to={ROUTES.socialMedia} className="header__menu__link">
                  Social media
                </Link>
                <Link to={ROUTES.seoMarketing} className="header__menu__link">
                  SEO marketing
                </Link>
                <Link to={ROUTES.legalAdvice} className="header__menu__link">
                  Legal advice
                </Link>
                <Link to={ROUTES.fundingAndInvestment} className="header__menu__link">
                  Funding & Investment
                </Link>
                <Link to={ROUTES.businessDesign} className="header__menu__link">
                  Business design
                </Link>
              </div>
            </div>
            <Link to={ROUTES.ourContacts} className="header__nav__link">
              Our contacts
            </Link>
          </nav>
          <Link to={ROUTES.contactUs} className="header__button">
            Contact us
          </Link>
          <div
            className={classNames("menu__icon", { transform: isMenuVisible })}
            onClick={() => setMenuVisibility(!isMenuVisible)}
          >
            <div className="menu__icon__line"></div>
            <div className="menu__icon__line"></div>
            <div className="menu__icon__line"></div>
          </div>
          <div className={classNames("menu", { opened: isMenuVisible })}>
            <div className="menu__header">
              <Link to={ROUTES.home} className="menu__logo__container" onClick={handleLinkClick}>
                <svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M31.9696 21.9351V0H28.5987H27.4779H25.8446V0.0327454L10.6536 15.3009L4.48951 9.10323V5.06463L10.1258 10.7296L13.3035 7.538L20.8034 0H14.4525L10.128 4.34641L5.8014 0H4.48951H0.0369239H0V0.0371115V21.9547V22.0071H0.0521279L10.1258 32.132L10.1671 32.0905L10.5298 32.4529L24.587 18.3244L24.5892 12.3516L10.4886 26.1134L4.48951 20.0839V15.4886L7.47818 18.4924L7.46732 18.5034L10.6428 21.6949L27.4779 4.77429V20.1275L12.8734 34.8084L16.0489 38L32 21.9656L31.9696 21.9351Z"
                    fill="white"
                  />
                </svg>
                <div className="menu__logo__text">
                  <span className="bold">Legal</span>
                  <span className="symbol">&</span>
                  <span className="bold">PR</span>
                  <br /> Solutions
                </div>
              </Link>
            </div>
            <div className="menu__content">
              <div className="menu__links__container">
                <div
                  className="menu__link__title dropdown"
                  onClick={() => setDropdownListVisibility(!isDropDownListVisible)}
                >
                  Our services
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames("menu__link__title__icon", { rotate: isDropDownListVisible })}
                  >
                    <path
                      d="M5.99983 4.97633L10.1247 0.851562L11.3032 2.03007L5.99983 7.33341L0.696533 2.03007L1.87505 0.851562L5.99983 4.97633Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className={classNames("menu__links", { expanded: isDropDownListVisible })}>
                  <div className="menu__links__column">
                    <Link to={ROUTES.publicRelations} className="menu__link" onClick={handleLinkClick}>
                      Public relations
                    </Link>
                    <Link to={ROUTES.contentMarketing} className="menu__link" onClick={handleLinkClick}>
                      Content marketing
                    </Link>
                    <Link to={ROUTES.socialMedia} className="menu__link" onClick={handleLinkClick}>
                      Social media
                    </Link>
                    <Link to={ROUTES.seoMarketing} className="menu__link" onClick={handleLinkClick}>
                      SEO marketing
                    </Link>
                  </div>
                  <div className="menu__links__column">
                    <Link to={ROUTES.legalAdvice} className="menu__link" onClick={handleLinkClick}>
                      Legal advice
                    </Link>
                    <Link to={ROUTES.fundingAndInvestment} className="menu__link" onClick={handleLinkClick}>
                      Funding & Investment
                    </Link>
                    <Link to={ROUTES.businessDesign} className="menu__link" onClick={handleLinkClick}>
                      Business design
                    </Link>
                  </div>
                </div>
              </div>
              <Link to={ROUTES.ourContacts} className="menu__link__title" onClick={handleLinkClick}>
                Our contacts
              </Link>
            </div>
            <Link to={ROUTES.contactUs} onClick={handleLinkClick} className="menu__button__container">
              <button className="menu__button">Contact us</button>
            </Link>
          </div>
        </div>
      </Container>
    </header>
  );
};
