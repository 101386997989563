import { useNavigate } from "react-router-dom";
import { FixedButton } from "../../components/FixedButton";
import { Title } from "../../components/Title";
import { HomeGrid } from "../../feature/Home/HomeGrid";
import "./Home.css";
import { ROUTES } from "../../constants/routes";

export const Home = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(ROUTES.contactUs);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Title className="home__title">
        <svg width="177" height="124" viewBox="0 0 177 124" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M102.716 123.431L176.023 123.431V98.6685L127.394 98.6685V84.192L176.023 84.192V20.175L88.2884 20.175V0L63.6101 0V20.1909L0 20.1909L0 44.9532H0.174015L63.1988 123.423L63.5784 123.042V123.431H88.2252V44.9532H151.282V59.4297H102.716V123.431ZM63.5784 84.9064L31.1485 44.9532H63.5784L63.5784 84.9064Z"
            fill="#2962B7"
          />
        </svg>
        Legal PR
      </Title>
      <div className="home__description">
        <span style={{ fontWeight: 400, fontSize: "1.2rem" }}>WE GET YOU KNOWN, PERIOD.</span>
        <br /> Our company has one singular focus: to securely scale your awareness. This means stronger online
        reputation, greater brand awareness, ultimately more sales and ease at doing business.
      </div>
      <HomeGrid />
      <FixedButton onClick={handleClick} />
    </>
  );
};
